<template>
  <div class="d-flex flex-column-fluid" style="">
    <!--begin::Container-->
    <div class="container">
      <div class="row mb-5">
        <b-button
          style="border-radius: 50px;"
          variant="secondary"
          @click="goToDash"
          class="btn font-weight-bolder text-uppercase btn-primary py-4 px-6 ml-4"
        >
          <div class="d-flex align-items-center">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
      <!--begin::Row-->
      <div class="row mb-5">
        <div class="col-lg-12">
          <!--begin::Callout-->
          <div class="card card-custom mb-2 cardround">
            <div
              class="cybertop-overlay d-flex justify-content-center"
              :style="`background-image: url(${bgImage})`"
            >
              <div class="cybertop-image" />
              <h2 class="align-self-center text-white">{{ header }}</h2>
            </div>
            <b-card-body class="card-body">
              <div
                class="d-flex align-items-center justify-content-between p-4"
              >
                <div
                  class="d-flex flex-column mr-5"
                  style="text-align: center; width: 100%;"
                >
                  <p
                    class="text-dark-75"
                    style="font-size: 1.8rem; text-align: center; font-weight: 500;"
                    v-html="question.title"
                  ></p>
                </div>
              </div>
              <p style="font-size: 1.2em;">
                {{ $t("training.cybertest.checkoption") }}
              </p>
              <br />
              <div
                class="d-flex align-items-center justify-content-between row"
              >
                <div
                  class="d-flex flex-column col-xl-4 answer ove"
                  v-for="(answer, answerIndex) in question.choices"
                  :key="answerIndex"
                >
                  <b-button
                    @click="doAnswer(answer.value, question, answerIndex)"
                    :disabled="isAnswered"
                    class="font-weight-bolder btn-shadow-hover"
                    :class="
                      preset !== false && preset.index === answerIndex
                        ? ''
                        : answerIndex === answeredIndex
                        ? question.valuesRelation[answer.value] === 1
                          ? 'text-white'
                          : 'text-white'
                        : question.valuesRelation[answer.value] === 1
                        ? isAnswered
                          ? 'light-primary'
                          : 'light'
                        : 'light'
                    "
                    v-html="answer.text"
                    style="border-radius: 60px"
                    :variant="
                      preset !== false && preset.index === answerIndex
                        ? 'secondary'
                        : answerIndex === answeredIndex
                        ? question.valuesRelation[answer.value] === 1
                          ? 'primary'
                          : 'danger'
                        : question.valuesRelation[answer.value] === 1
                        ? isAnswered
                          ? 'light-primary'
                          : 'light'
                        : 'light'
                    "
                  />
                </div>
              </div>
            </b-card-body>
          </div>
          <transition enter-active-class="animate__animated animate__bounceIn">
            <div
              class="card card-custom mb-2 mt-8 p-4 cardround"
              id="answer"
              style="border-radius: 100px !important;"
              v-show="isAnswered"
            >
              <b-card-body class="text-dark-50 pl-15">
                <div
                  class="d-flex flex-column"
                  style="text-align: left; font-size: 1.2em; padding-right: 4.5em;"
                >
                  <p v-html="message" />
                </div>
              </b-card-body>
            </div>
          </transition>
          <div class="flex flex-column mt-10">
            <h4>
              {{ $t("training.cybertest.question") }}
              <strong>
                {{
                  answeredIndex === false
                    ? session.totalelementsdone + 1
                    : session.totalelementsdone
                }}
                / {{ session.totalelements }}
              </strong>
            </h4>
            <b-progress
              :value="percentageProgress"
              :max="100"
              variant="primary"
              striped="striped"
              animated
              class="mt-5 w-50 ml-auto mr-auto"
            />
          </div>
          <div
            class="d-flex flex-column mt-8"
            style="text-align: center;"
            v-if="isAnswered"
          >
            <div class="ml-6 flex-shrink-0">
              <b-button
                style="border-radius: 50px;"
                v-if="!markFinished && !loading"
                tag="a"
                variant="success"
                @click="next()"
                class="btn font-weight-bolder btn-primary py-4 px-6"
              >
                <span style="font-size: 1.3rem;">
                  {{ $t("generic.buttons.next") }}
                  <i class="flaticon2-next"></i>
                </span>
              </b-button>
              <b-button
                style="border-radius: 50px;"
                v-if="markFinished"
                variant="success"
                @click="finishTest(session.surveyEntityId)"
                class="btn font-weight-bolder text-uppercase btn-primary py-4 px-6"
                >{{ $t("generic.buttons.finish") }}</b-button
              >
            </div>
          </div>
          <!--end::Callout-->
        </div>
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import _ from "lodash";
import store from "@/store";
import VueScrollTo from "vue-scrollto";
import { getUserInteraction, saveInteraction } from "@/api/user/traits.api";
import { mapGetters } from "vuex";

export default {
  name: "CustomBotOneAnswer",
  data() {
    return {
      session: {},
      actualIndex: 0,
      isAnswered: false,
      message: "",
      isCorrect: null,
      answeredIndex: false,
      changeImage: false,
      bgImage: "",
      markFinished: false,
      initialElementsDone: 0,
      preset: false,
      loading: false,
      questions: [],
      images: [],
      header: ""
    };
  },
  methods: {
    goToDash() {
      store.dispatch("Profile/reset");
      store.dispatch("User/reset");
      store.dispatch("Interactions/reset");
      store.dispatch("User/reset_bot");
      this.$router.push({
        name: "Dashboard"
      });
    },
    finishTest(id) {
      setTimeout(() => {
        store.dispatch("Profile/reset");
        store.dispatch("User/reset");
        store.dispatch("Interactions/reset");
        store.dispatch("User/reset_bot");
        this.$router.push({
          name: "TrainingDetail",
          params: { id: id }
        });
      }, 500);
    },
    preselect(value, question, index) {
      this.preset = {
        active: true,
        value: value,
        question: question,
        index: index
      };
    },
    doAnswer(value, question, index) {
      this.loading = true;
      var answer = {};
      answer[question.name] = value;
      this.preset = false;
      let toSend = {
        entityId: this.session.entityId,
        entityType: this.session.entityType,
        stakeholderCompanyId: this.session.stakeholderCompanyId,
        surveyId: this.session.surveyId,
        surveyEntityId: this.session.surveyEntityId,
        surveyTypeId: this.session.surveyTypeId,
        templateSurveyId: this.session.templateSurveyId,
        totalelements: this.session.totalelements,
        totalelementsdone: this.session.totalelementsdone,
        stakeholderId: this.session.stakeholderId,
        questions: answer
      };
      this.session.totalelementsdone++;
      if (this.session.totalelements === this.session.totalelementsdone) {
        this.markFinished = true;
      }
      saveInteraction(this.$route.params.surveyEntityId, toSend).then(
        response => {
          this.isCorrect = value === 1;
          this.isAnswered = true;
          this.answeredIndex = index;
          this.message = _.find(response.data.records.questions, e => {
            return e.type === "info";
          })?.title;
          var question = _.find(response.data.records.questions, e => {
            return e.type === "radiogroup";
          });
          if (question) this.questions.push(question);
          setTimeout(() => {
            VueScrollTo.scrollTo("#answer", 1000);
            this.loading = false;
          }, 200);
        }
      );
    },
    next() {
      this.message = "";
      this.isCorrect = null;
      this.isAnswered = false;
      this.answeredIndex = false;
      this.actualIndex++;
      this.getRandomImageForQuestion();
    },
    getRandomImageForQuestion() {
      this.bgImage = this.images[
        Math.floor(Math.random() * this.images.length)
      ];
    }
  },
  computed: {
    ...mapGetters("Chatbot", ["extraConfig"]),
    percentageProgress() {
      return (
        (this.session.totalelementsdone / this.session.totalelements) * 100
      );
    },
    question() {
      return this.questions[this.actualIndex]
        ? this.questions[this.actualIndex]
        : {};
    }
  },
  beforeCreate() {
    getUserInteraction(this.$route.params.surveyEntityId).then(response => {
      this.session = response.data.records;
      this.questions = response.data.records.questions;
      this.initialElementsDone = this.session.totalelementsdone;
      this.images = response.data.records.extraConfig.images;
      this.header = response.data.records.extraConfig.header;
      this.getRandomImageForQuestion();
    });
  },
  created() {
    window.addEventListener("keypress", this.keyEvent);
  },
  destroyed() {
    window.removeEventListener("keypress", this.keyEvent);
  }
};
</script>

<style lang="scss" scoped>
.set-to-top {
  position: relative;
  .card {
    position: absolute;
    top: 0;
  }
}
.answer .btn.btn-primary {
  font-weight: bold;
}
.answer .btn.btn-primary.text-white.disabled {
  opacity: 1 !important;
}
.cybertop-overlay {
  min-height: 170px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  .cybertop-image {
    background-color: #00000061;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
  .tcicon {
    position: absolute;
    top: 1em;
    right: 1em;
    color: white;
    font-size: 4em;
  }
}
.floating-swal {
  position: absolute;
  right: 1.5em;
  top: 25%;
  background: transparent;
}
.answer .btn {
  text-align: center;
  font-size: 1.2rem;
  border-radius: 30px;
  padding: 1.2rem;
  min-width: 100%;
  min-height: 170px;
  &.btn-secondary {
    background-color: #b5b5c3;
  }
}
kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.95em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}
</style>
